import React, { useEffect } from 'react'
import { Button, Spin, Carousel } from 'antd'
import "./room.scss"
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationRoom, fetchRooms, idleRooms } from './redux/roomSlice'
import { IoBed } from "react-icons/io5"
import { MdPerson } from "react-icons/md"
import { IoMdHeart } from "react-icons/io"
import { list } from '../../data/Data'

interface Props {
  applicationId: string
}

const getRoomTypeName = (type: number | undefined, t: (key: string) => string) => {
  switch (type) {
    case 0:
      return t('Big Double Room')
    case 1:
      return t('Double Room')
    case 2:
      return t('Master Room')
    case 3:
      return t('Lodging')
    case 4:
      return t('Family Room')
    case 5:
      return t('Big Simple Room')
    default:
      return t('Simple Room')
  }
}

function RoomCard({ applicationId }: Props) {
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation('rooms')
  const navigate = useNavigate()
  const apiURL = process.env.REACT_APP_API_URL
  const roomsFromRedux = useSelector((state: RootState) => state.room.rooms)
  const fetchRoomsStatus = useSelector((state: RootState) => state.room.fetchRoomsStatus)

  const redirectToServicePage = (id: string | undefined) => {
    navigate(`/rent/${id}`)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (apiURL !== "https://aucoeur-back-main.onrender.com") {
      dispatch(fetchRooms({ applicationId }))
    }
    return () => {
      dispatch(idleRooms())
    }
  }, [applicationId, dispatch, apiURL])

  const rooms = apiURL === "https://aucoeur-back-main.onrender.com" ? list : roomsFromRedux

  const getImages = (cover: string, additionalImages: string[] | undefined): string[] => {
    return additionalImages ? [cover, ...additionalImages] : [cover]
  }

  return (
    <div className='content grid3 mtop'>
      {rooms.map((val: ApplicationRoom, index: number) => {
        const { name, _id, cover, active, capacity, numBeds, additionalImages, type } = val
        const coverImg = cover ? cover : "../images/additional/chambre1.jpg"
        const additionalImg = additionalImages ? additionalImages : ["../images/additional/chambre2.PNG", "../images/additional/chambre3.PNG", "../images/additional/chambre4.PNG", "../images/additional/photo8.jpg", "../images/additional/photo10.jpg"]
        const images = getImages(coverImg, additionalImg)

        const roomName = `${getRoomTypeName(type, t)} ${name || ''}`
        return (
          <div
            className="box shadow"
            key={index}
            onClick={() => redirectToServicePage(_id)}
          >
           <div onClick={(e) => e.stopPropagation()}>
            <Carousel
              arrows
              autoplay
              dots={images.length > 1}
              draggable
            >
              {images.map((image, i) => (
                <div key={i}>
                  <img src={image} alt="" className="carousel-img" />
                </div>
              ))}
            </Carousel>
           </div>
            <div className='text'>
              <div className='category flex'>
                <span
                  style={{
                    background: active ? '#25b5791a' : '#ff98001a',
                    color: active ? '#25b579' : '#ff9800',
                  }}
                >
                  {active ? t('Available') : t('Unavailable')}
                </span>
              </div>
              <h4>{roomName}</h4>
            </div>
            <div className='button flex'>
              <div>
                <Button type='primary' className='btn2' size='large'>
                  <IoMdHeart />
                </Button>
              </div>
              <div className='icons'>
                {capacity} x
              </div><MdPerson size={20} />
              <div className='icons'>
                {numBeds} x 
              </div> <IoBed size={20}/>
            </div>
          </div>
        )
      })}
      {fetchRoomsStatus !== 'success' && apiURL !== "https://aucoeur-back-main.onrender.com" && (
        <div className="loading-container">
          <Spin />
        </div>
      )}
    </div>
  )
}

export default RoomCard
