import React, { useState } from 'react'
import { Layout, Menu, Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './header.scss'
import { LanguageSwitch } from '../../LanguageSwitch'
import { useTranslation } from 'react-i18next'

const { Header, Content } = Layout

interface Props {
  children: React.ReactNode
}

function AppLayout(props: Props) {
  const location = useLocation()
  const { t } = useTranslation("common")
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()

  const handleLinkClick = (path: string) => {
    navigate(path)
    window.scrollTo(0, 0)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const nav = [
    {
      text: "home",
      path: "/",
    },
    {
      text: "about",
      path: "/about",
    },
    {
      text: "services",
      path: "/services",
    },
    {
      text: "gallery",
      path: "/gallery",
    },
    {
      text: "rooms",
      path: "/rooms",
    },
    {
      text: "contact us",
      path: "/contact",
    },
  ]

  return (
    <Layout>
      <Header>
        <Link to="/">
          <img className="logo-image" src={`/images/logo.png`} alt="Logo" />
        </Link>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={[location.pathname]} className="desktop-menu">
          {nav.map((item, index) => (
            <Menu.Item key={item.path}>
              <a onClick={() => handleLinkClick(item.path)}>{t(item.text)}</a>
            </Menu.Item>
          ))}
        </Menu>
        <Button className="mobile-menu-button" type="primary" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
        <Drawer title="Menu" placement="right" onClose={onClose} visible={visible}>
          <Menu theme="light" mode="vertical" defaultSelectedKeys={[location.pathname]}>
            {nav.map(item => (
              <Menu.Item key={item.path}>
                <a onClick={() => handleLinkClick(item.path)}>{t(item.text)}</a>
              </Menu.Item>
            ))}
          </Menu>
        </Drawer>
        <LanguageSwitch />
      </Header>
      <Content>{props.children}</Content>
    </Layout>
  )
}

export default AppLayout
