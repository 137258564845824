import './heading.scss'

type HeadingProps = {
  title: string
  subtitle: React.ReactNode
}

const Heading: React.FC<HeadingProps> = ({ title, subtitle }) => {
  return (
    <>
      <div className="heading">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </>
  )
}

export default Heading