import React, { useEffect, useState } from 'react'
import "./contact.scss"
import { Button, Form, Input, message } from 'antd'
import { useTranslation } from "react-i18next"
import { AppDispatch, RootState } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { sendDemandContact, idledemandContactStatus } from './redux/demandContactSlice'

const Contact: React.FC = () => {
  const { t } = useTranslation('contact')
  const name = t("Your name")
  const email = t("Email")
  const phone = t("Phone number")
  const [selectedFile, setSelectedFile] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const [form] = Form.useForm()
  const demandContactStatus = useSelector(
    (state: RootState) => state.demandContact.demandContactStatus,
  )

  useEffect(() => {
    switch (demandContactStatus) {
      case 'success':
        message.success(t('Your demand of contact has been successfully sent.'))
        setSelectedFile(null)
        setSubmitting(false)
        form.resetFields()
        break
      case 'error':
        message.error(t('An error occurred.', { ns: 'common' }))
        setSubmitting(false)
        break
    }

    return () => {
      dispatch(idledemandContactStatus())
    }
  }, [demandContactStatus, dispatch, form, t])

  const handleContactSubmit = async (values: { name: string; email: string; phone: string; message: string }) => {
    if (submitting) return
    setSubmitting(true)

    const trimmedEmail = values.email.trim()
    const formData = {
      name: values.name,
      email: trimmedEmail,
      phone: values.phone,
      message: values.message,
      file: selectedFile ? [selectedFile] : [],
    }
    dispatch(sendDemandContact(formData))
  }

  const uploadProps = {
    beforeUpload: (file: any) => {
      if (file.type === 'application/pdf') {
        setSelectedFile(file)
      } else {
        message.error(t('NOT_PDF', { filename: file.name }))
      }
      return false
    },
    fileList: selectedFile ? [selectedFile] : [],
    onRemove: () => {
      setSelectedFile(null)
    },
  }

  return (
    <section className="contact mb">
      <div className="back">
        <div className="container">
          <span className="intro">{t('Contact Us')}</span>
          <h1 className="intro hide-on-mobile">{t("Feel free to ask us")}</h1>
        </div>
        <img src="./images/back.jpg" alt="" />
      </div>
      <div className="container">
        <Form 
          form={form} 
          className="shadow" 
          onFinish={handleContactSubmit} 
          autoComplete='off'
          disabled={submitting}
        >
          <h4>{t('Fill up The Form')}</h4> <br />
          <Form.Item name="name" rules={[{ required: true, message: t('Please input your name') as string }]}>
            <Input placeholder={name} disabled={submitting} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('Please let us contact you by your email') as string },
              { type: 'email', message: t('Please enter a valid email address', { ns: 'common' }) as string, validateTrigger: 'onSubmit' }
            ]}
          >
            <Input placeholder={email} disabled={submitting} />
          </Form.Item>
          <Form.Item name="phone">
            <Input placeholder={phone} disabled={submitting} />
          </Form.Item>
          <Form.Item
            name="message"
            rules={[{ required: true, message: t('Please input your message') as string}]}
          >
            <Input.TextArea rows={10} disabled={submitting} />
          </Form.Item>
          <Button 
            type="primary" 
            htmlType="submit" 
            loading={submitting || demandContactStatus === 'loading'}
            disabled={submitting || demandContactStatus === 'loading'}
          >
            {t('Submit Request')}
          </Button>
        </Form>
      </div>
    </section>
  )
}

export default Contact