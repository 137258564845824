import React from 'react'
import Heading from "../../common/Heading"
import "./room.scss"
import RoomCard from "./RoomCard"
import { useTranslation } from 'react-i18next'
import APPLICATIONS from "../../data/Applications"
import { useNavigate } from "react-router-dom"

const Room = () => {
  const applicationId = APPLICATIONS[0].id
  const { t } = useTranslation('home')
  const navigate = useNavigate()

  const handleShowRooms = () => {
    navigate('/rooms')
  }

  return (
    <section className='room padding'>
      <div className='container'>
        <Heading 
          title={t('Our rooms', { ns: 'rooms' })} 
          subtitle={t('Experience the pinnacle of grandeur in our rooms. Live your finest moments with Le Corvier Castle.')} 
        />
        <div className="room-container">
          <RoomCard applicationId={applicationId} />
        </div>
        <div className="button-container">
          <button onClick={handleShowRooms} className="show-rooms-button">
            {t('Show More')}
          </button>
        </div>
      </div>
    </section>
  )
}

export default Room
