import "../home/featuredServices/Featured.scss"
import FeaturedCard from "../home/featuredServices/FeaturedCard"
import { useTranslation } from 'react-i18next'

const Services = () => {
  const { t } = useTranslation('services')
  return (
    <>
      <section className='services mb'>
        <div className="back">
          <div className="container">
            <span className="intro">{t('Services')}</span>
            <h1 className="intro hide-on-mobile">{t('Our services')}</h1>
          </div>
          <img src='./images/back.jpg' alt="" />
        </div>
        <div className='featured container'>
          <FeaturedCard />
        </div>
      </section>
    </>
  )
}

export default Services
