import { useState } from "react"
import { Gallery } from "react-grid-gallery"
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"
import { images, CustomImage } from "./images"
import "../home/featuredServices/Featured.scss"
import { useTranslation } from 'react-i18next'

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}))

const GalleryComponent: React.FC = () => {
  const { t } = useTranslation('gallery')
  const [index, setIndex] = useState(-1)

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <>
      <section className='gallery mb'>
        <div className="back hero">
          <div className="container">
            <span className="intro">{t('Gallery')}</span>
            <h1 className="intro hide-on-mobile">{t('Our pictures')}</h1>
          </div>
          <img src='./images/back.jpg' alt="" />
        </div>
        <div className='container'>
          <Gallery
            images={images.map(image => ({
              ...image,
              alt: "Gallery Image",
              customOverlay: <div>{image.caption}</div>
            }))}
            onClick={handleClick}
            enableImageSelection={false}
          />
          <Lightbox
            slides={slides}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
          />
        </div>
      </section>
    </>
  )
}
export default GalleryComponent
