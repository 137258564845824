import React from "react"
import Heading from "../common/Heading"
import "./about.scss"
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation('about')
  const redirectToServicePage = () => {
    window.open('https://aucoeurservice.fr', '_blank')
  }

  return (
    <>
      <section className='about'>
        <div className="back">
          <div className="container">
            <h1 className="intro">{t('About Our Castle')}</h1>
          </div>
          <img src='./images/back.jpg' alt="" />
        </div>
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title={t('Le Corvier Castle', { ns: 'common' })} subtitle={t('Delve into the enchanting saga of our castle.')} />
            <p>{t('The Corvier Castle, managed by Aucoeur Service, is a magical place where one would want to stop time. Guests can wander in a forty-hectare park, wooded with extraordinary trees. A heated pool (in season) is available, as well as a barbecue. You will appreciate this stop where time takes a pause. Calm and space, comfort and the pleasure of an exchanged conversation by the fireplace.')}</p>
            <p>{t('This Louis XIII style castle, located near the A71 motorway and Lamotte Beuvron (the hometown of the Tatin sisters and the tarte Tatin), will welcome you in its bright lounges and tastefully appointed guest rooms. Ideally located close to the Loire castles (Chambord, Cheverny, Sully sur Loire, Blois...), the federal equestrian park, and 4 eighteen or twenty-seven hole golf courses, whether it is the Aisses, Marcilly, Sologne, or Limère golf courses, we will be delighted to welcome you.')}</p>
            <Button type="primary" className='btn2' onClick={redirectToServicePage} size="large">
              {t('About Aucoeur Service')}
            </Button>
          </div>
          <div className='right row'>
            <img src='./images/about.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
