import React from "react"
import { footer } from "../../data/Data"
import "./footer.scss"
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"

const Footer = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const redirectToServicePage = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    if(path != ""){
      if(path.slice(0, 5) === "https"){
        window.open(path, '_blank')
      }
      else{
        navigate(path)
        window.scrollTo(0, 0)
      } 
    }
  }

  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>{t('You have a question ?')}</h1>
              <p>{t("Don't hesitate to contact us.")}</p>
            </div>
            <a href="/contact">
              <button className='btn5'>{t('Contact us now')}</button>
            </a>
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
              <img src='../images/logo.png' alt="Le Corvier" />
              <h2>Château Le Corvier</h2>
            </div>
          </div>

          {footer.map((val) => (
            <div className='box'>
              <h3>{t(val.title)}</h3>
              <ul>
                {val.text.map((items) => (
                  <li> 
                    <a onClick={(event) => redirectToServicePage(event, items.path)}>{t(items.list)}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className='legal'>
      </div>
    </>
  )
}

export default Footer
