interface Application {
    id: string
    name: string
}

const APPLICATIONS: Application[] = []

if (process.env.REACT_APP_API_URL === "http://localhost:5000") {
  APPLICATIONS.unshift({
    id: 'lecorvier-local',
    name: "Château Le Corvier",
  })
} else if (process.env.REACT_APP_API_URL === "https://aucoeur-back-media.onrender.com") {
  APPLICATIONS.unshift({
    id: 'lecorvier-demo',
    name: "Château Le Corvier",
  })
} else {
  APPLICATIONS.unshift({
    id: 'lecorvier-prod',
    name: "Château Le Corvier",
  })
}

export default APPLICATIONS
