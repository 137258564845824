export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Camping service",
    total: "More then 40-ha Forest",
    path: "https://drive.google.com/file/d/1UONnbRzGsSAl1noqNyeiVecLe81lSgis/view?usp=sharing"
  },
  {
    cover: "../images/hero/h4.png",
    name: "Wedding services",
    total: "Outstanding conference room",
    path: "https://drive.google.com/file/d/1UONnbRzGsSAl1noqNyeiVecLe81lSgis/view?usp=sharing"
  },
  {
    cover: "../images/hero/h6.png",
    name: "Other entertainment services",
    total: "With more then 20 rooms",
    path: "https://drive.google.com/file/d/1UONnbRzGsSAl1noqNyeiVecLe81lSgis/view?usp=sharing"
  },
]

export const list = [
  {
    _id: "1",
    applicationId: "lecorvier",
    cover: "../images/list/chambre1.jpg",
    name: "Eglantine",
    active: true,
    numBeds: 2,
    capacity: 2,
    type: 4,
    additionalImages: ["../images/additional/photo1.jpg","../images/additional/photo2.jpg","../images/additional/photo3.jpg","../images/additional/photo4.jpg"]
  },
  {
    _id: "2",
    applicationId: "lecorvier",
    cover: "../images/list/chambre2.PNG",
    name: "Lavande",
    active: true,
    numBeds: 2,
    capacity: 2,
    type: 6,
    additionalImages: []
  },
  {
    _id: "3",
    applicationId: "lecorvier",
    cover: "../images/list/chambre3.PNG",
    name: "Pivoine",
    active: false,
    numBeds: 2,
    capacity: 2,
    type: 2,
    additionalImages: ["../images/additional/photo5.jpg","../images/additional/photo6.jpg"]
  },
  {
    _id: "4",
    applicationId: "lecorvier",
    cover: "../images/list/chambre4.PNG",
    name: "Bleuet",
    active: true,
    numBeds: 3,
    capacity: 4,
    type: 0,
    additionalImages: ["../images/additional/photo7.jpg", "../images/additional/photo8.jpg"]
  },
  {
    _id: "5",
    applicationId: "lecorvier",
    cover: "../images/list/chambre5.PNG",
    name: "Iris",
    active: false,
    numBeds: 2,
    capacity: 2,
    type: 3,
    additionalImages: ["../images/additional/photo10.jpg"]
  },
  {
    _id: "6",
    applicationId: "lecorvier",
    cover: "../images/list/chambre6.PNG",
    name: "Mimosa",
    active: true,
    numBeds: 2,
    capacity: 2,
    type: 5,
    additionalImages: ["../images/additional/photo12.jpg"]
  },
]

export const footer = [
  {
    title: "PAGES",
    text: [{ list: "Home", path: "/", }, { list: "About", path: "/about", }, { list: "Service", path: "/services", }, { list: "Gallery", path: "/gallery", }, { list: "Rooms", path: "/rooms", }, { list: "Contact", path: "/contact", }],
  },
  {
    title: "LOCATION",
    text: [{ list: "Corvier, 41600 Vouzon", path: "https://www.google.fr/maps/place/Bed+%26+Breakfast+-+Ch%C3%A2teau+du+Corvier/@47.6469342,1.9552614,17z/data=!3m1!4b1!4m9!3m8!1s0x47e4d9183eac8d21:0xaef87355a7bd2301!5m2!4m1!1i2!8m2!3d47.6469306!4d1.9578363!16s%2Fg%2F1vntj5mf?dcr=0&entry=tts&g_ep=EgoyMDI0MDYwNS4wKgBIAVAD",}, { list: "06 23 21 21 89", path: "", },  { list: "06 65 78 27 76", path: "", },  { list: "06 29 37 48 61", path: "", },  { list: "aucoeur.contact@gmail.com", path: "", }],
  },
  {
    title: "FOLLOW US",
    text: [{ list: "Facebook", path: "https://www.facebook.com/dianescozyhouse", }, { list: "TikTok", path: "https://www.tiktok.com/@chateau.du.corvier2023", }, { list: "Aucoeur Service", path: "https://aucoeurservice.fr", }],
  },
]
