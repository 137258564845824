import React from 'react'
import { Link } from 'react-router-dom'
import './not_found.scss'
import { useTranslation } from 'react-i18next'

function NotFound() {
    const { t } = useTranslation('notfound')  
    return (
        <div className="not-found">
        <h1>{t('Page not found')}</h1>
        <Link to="/">{t('Go to start page')}</Link>
        </div>
    )
    }

export default NotFound
