import React, { useEffect, useState } from 'react'
import Heading from "../../common/Heading"
import "./hero.scss"
import { useTranslation } from 'react-i18next'
import { Button, Select, Form, Input, DatePicker, InputNumber, message } from 'antd'
import dayjs from 'dayjs'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import APPLICATIONS from "../../data/Applications"
import { createReservation, idleReservationStatus } from '../../calendar/calendarEventSlice'

const { RangePicker } = DatePicker

const Hero = () => {
  const { t } = useTranslation('home')
  const nameApplication = APPLICATIONS[0]?.name
  const name = t("Your name")
  const email = t("Your email")
  //const phone = t("Phone number")
  const dispatch = useDispatch<AppDispatch>()
  const [addRoomEventForm] = Form.useForm()
  const reservationStatus = useSelector((state: RootState) => state.calendar)
  const [submitting, setSubmitting] = useState(false)

  const createEvent = (values: { dates: [dayjs.Dayjs, dayjs.Dayjs], typeService: string, occupants: number, fullname: string, email: string, phone: string }) => {
    if (submitting) return
      setSubmitting(true)

    if (nameApplication) {
      const [startDate, endDate] = values.dates
      const newNote = values.fullname + " voudrait réserver pour motif " + values.typeService + ", son email est: " + values.email.trim()
      const contactData = {
        name: values.fullname,
        email: values.email.trim(),
        message: newNote,
      }
      const event = {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        roomId: nameApplication,
        occupants: values.occupants,
        type: "Demand - Reservation online",
        note: newNote,
      }
      dispatch(createReservation({ contactData, event }))
    }
  }

  useEffect(() => {
    if (reservationStatus.demandContactStatus === 'success' && reservationStatus.fetchCreateEventStatus === 'success') {
      message.success(t('Your demand of reservation has been successfully sent.'))
      addRoomEventForm.resetFields()
      setSubmitting(false)
    } else if (reservationStatus.demandContactStatus === 'error' || reservationStatus.fetchCreateEventStatus === 'error') {
      message.error(t('An error occurred.', { ns: 'common' }))
      setSubmitting(false)
    }
    return () => {
      dispatch(idleReservationStatus())
    }
  }, [reservationStatus, t, addRoomEventForm, dispatch])

  return (
    <>
      <section className='hero'>
        <div className='container'>
          <Heading title={t('Le Corvier Castle', { ns: 'common' })} subtitle={t('Discover the adventure of a lifetime in our magnificent castle, nestled in the heart of French nature, where history meets luxury in a setting that is both enchanting and unforgettable.')} />
          <div className='form box'>
            <span>{t('Ask us for reservation')}</span>
            <Form
              form={addRoomEventForm}
              autoComplete="off"
              layout="inline"
              onFinish={createEvent}
              disabled={submitting}
            >
              <Form.Item
                name="dates"
                rules={[{ required: true, message: t('Please input dates') || "" }]}
              >
                <RangePicker
                  showTime
                  format="DD/MM/YYYY HH:mm"
                  inputReadOnly
                  placeholder={[t('Start date'), t('End date')]}
                  disabledDate={(current) =>
                    current && dayjs(current).isBefore(dayjs().subtract(1, 'day'))
                  }
                  disabled={submitting}
                />
              </Form.Item>
              <Form.Item
                name="typeService"
                style={{ width: "10em" }}
              >
                <Select
                  defaultOpen={false}
                  disabled={submitting}
                >
                  <Select.Option value={'Louer chateau entier'}>
                    {t('Castle rental')}
                  </Select.Option>
                  <Select.Option value={'Camping'}>
                    {t('Camping')}
                  </Select.Option>
                  <Select.Option value={'Mariage'}>
                    {t('Marriage')}
                  </Select.Option>
                  <Select.Option value={'Autre'}>
                    {t('Other')}
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="occupants"
              >
                <InputNumber
                  min={1}
                  max={60}
                  style={{ width: '7.5em' }}
                  addonAfter={t('pers', { ns: 'common' })}
                  disabled={submitting}
                />
              </Form.Item>
              <Form.Item name="fullname" rules={[{ required: true, message: t('Please input your name') as string }]}>
                <Input placeholder={name}  disabled={submitting} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: t('Please enter your email') as string },
                  { type: 'email', message: t('Please enter a valid email address', { ns: 'common' }) as string, validateTrigger: 'onSubmit' }
                ]}
              >
                <Input placeholder={email}  disabled={submitting} />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={submitting || reservationStatus.demandContactStatus === 'loading'}
                disabled={submitting || reservationStatus.demandContactStatus === 'loading'}
              >
                {t('Send')}
              </Button>
            </Form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
