import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Rooms from "../rooms/Rooms"
import Services from "../services/Services"
import Rent from "../rental/Rent"
import Gallery from "../gallery/Gallery"
import Contact from "../contact/Contact"
import AppLayout from "../common/header/AppLayout"
import NotFound from "../notfound/NotFound"

const Pages = () => {
  return (
    <>
      <BrowserRouter>
        <AppLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/rent/:id" element={<Rent />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppLayout>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default Pages