import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { FetchStatus } from "../../../contact/redux/demandContactSlice"

export interface ApplicationRoom {
  applicationId: string
  name: string
  numBeds: number
  capacity: number
  active: boolean
  cover?: string
  additionalImages?: string[]
  type?: number
  category?: string
  description?: string
  price?: number
  startDate?: Date
  createdAt?: Date
  floor?: number
  _id?: string
}

interface State {
  roomsTotalNumber: number
  room: ApplicationRoom | undefined
  rooms: ApplicationRoom[]
  getRoomStatus: FetchStatus
  getRoomError?: string
  fetchRoomsStatus: FetchStatus
  fetchRoomsError?: string
}

const initialState: State = {
  roomsTotalNumber: 0,
  room: undefined,
  rooms: [],
  getRoomStatus: "idle",
  fetchRoomsStatus: "idle",
}

export const getRoom = createAsyncThunk(
  'room/getRoom',
  async ({ roomId }: { roomId: string }, { getState }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/room/${roomId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new Error(reason)
    }

    return await response.json()
  }
)

export const fetchRooms = createAsyncThunk(
  'room/fetchRooms',
  async ({ applicationId }: { applicationId: string }, { getState }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/room/application/${applicationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      const reason = (await response.json()).reason || "unknown_error"
      throw new Error(reason)
    }

    return await response.json()
  }
)

const roomsSlice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    idleRooms: (state) => {
      state.getRoomStatus = "idle"
      state.getRoomError = undefined
      state.fetchRoomsStatus = "idle"
      state.fetchRoomsError = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoom.pending, (state) => {
        state.fetchRoomsStatus = "loading"
      })
      .addCase(getRoom.fulfilled, (state, { payload }: PayloadAction<{ room: ApplicationRoom }>) => {
        state.fetchRoomsStatus = "success"
        state.room = payload.room
      })
      .addCase(getRoom.rejected, (state, action) => {
        state.fetchRoomsStatus = "error"
        state.fetchRoomsError = action.error.message
      })
      .addCase(fetchRooms.pending, (state) => {
        state.fetchRoomsStatus = "loading"
      })
      .addCase(fetchRooms.fulfilled, (state, { payload }: PayloadAction<{ rooms: ApplicationRoom[], totalNumber: number }>) => {
        state.fetchRoomsStatus = "success"
        state.rooms = payload.rooms
        state.roomsTotalNumber = payload.totalNumber
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.fetchRoomsStatus = "error"
        state.fetchRoomsError = action.error.message
      })
  }
})

export const {
  idleRooms
} = roomsSlice.actions

export default roomsSlice.reducer
