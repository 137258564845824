import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import homeEN from './trans/Home/en.json'
import homeFR from './trans/Home/fr.json'
import homeVN from './trans/Home/vn.json'
import commonEN from './trans/Common/en.json'
import commonFR from './trans/Common/fr.json'
import commonVN from './trans/Common/vn.json'
import aboutEN from './trans/About/en.json'
import aboutFR from './trans/About/fr.json'
import aboutVN from './trans/About/vn.json'
import roomEN from './trans/Rooms/en.json'
import roomFR from './trans/Rooms/fr.json'
import roomVN from './trans/Rooms/vn.json'
import serviceEN from './trans/Services/en.json'
import serviceFR from './trans/Services/fr.json'
import serviceVN from './trans/Services/vn.json'
import galleryEN from './trans/Gallery/en.json'
import galleryFR from './trans/Gallery/fr.json'
import galleryVN from './trans/Gallery/vn.json'
import contactEN from './trans/Contact/en.json'
import contactFR from './trans/Contact/fr.json'
import contactVN from './trans/Contact/vn.json'
import notfoundEN from './trans/NotFound/en.json'
import notfoundFR from './trans/NotFound/fr.json'
import notfoundVN from './trans/NotFound/vn.json'

export const resources = {
  en: {
    home: homeEN,
    common: commonEN,
    about: aboutEN,
    rooms: roomEN,
    services: serviceEN,
    gallery: galleryEN,
    contact: contactEN,
    notfound: notfoundEN,
  },
  fr: {
    home: homeFR,
    common: commonFR,
    about: aboutFR,
    rooms: roomFR,
    services: serviceFR,
    gallery: galleryFR,
    contact: contactFR,
    notfound: notfoundFR,
  },
  vn: {
    home: homeVN,
    common: commonVN,
    rooms: roomVN,
    services: serviceVN,
    gallery: galleryVN,
    about: aboutVN,
    contact: contactVN,
    notfound: notfoundVN,
  },
} as const


i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("settingsLng") || 'fr' || 'vn',
    fallbackLng: 'en',
    resources,
    nsSeparator: false
  })


i18n.on("languageChanged", lng => {
  localStorage.setItem("settingsLng", lng)
})
