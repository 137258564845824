import React, { useEffect } from "react"
import RoomCard from "../home/room/RoomCard"
import "../home/room/room.scss"
import { useTranslation } from 'react-i18next'
import APPLICATIONS from "../data/Applications"

const Rooms = () => {
  const applicationId = APPLICATIONS[0].id
  const { t } = useTranslation('rooms')
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className='blog-out mb'>
        <div className="back">
          <div className="container">
            <span className="intro">{t('Our rooms')}</span>
            <h1 className="intro hide-on-mobile">{t('Our cozy rooms')}</h1>
          </div>
          <img src='./images/back.jpg' alt="" />
        </div>
        <div className='container room'>
          <RoomCard applicationId={applicationId} />
        </div>
      </section>
    </>
  )
}

export default Rooms
