import { Image } from "react-grid-gallery"

export interface CustomImage extends Image {
  original: string
}

export const images: CustomImage[] = [
  {
    src: "/images/gallery/photo1.jpg",
    original: "/images/gallery/photo1.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "../images/gallery/photo2.jpg",
    original: "../images/gallery/photo2.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "../images/gallery/photo3.jpg",
    original: "../images/gallery/photo3.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "../images/gallery/photo4.jpg",
    original: "../images/gallery/photo4.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo5.jpg",
    original: "../images/gallery/photo5.jpg",
    width: 320,
    height: 183,
    caption: "",
  },
  {
    src: "../images/gallery/photo6.jpg",
    original: "../images/gallery/photo6.jpg",
    width: 240,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo7.jpg",
    original: "../images/gallery/photo7.jpg",
    width: 320,
    height: 190,
    caption: "",
  },
  {
    src: "../images/gallery/photo8.jpg",
    original: "../images/gallery/photo8.jpg",
    width: 320,
    height: 148,
    caption: "",
  },
  {
    src: "../images/gallery/photo9.jpg",
    original: "../images/gallery/photo9.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo10.jpg",
    original: "../images/gallery/photo10.jpg",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo11.jpg",
    original: "../images/gallery/photo11.jpg",
    width: 320,
    height: 113,
    caption: "",
  },
  {
    src: "../images/gallery/photo12.jpg",
    original: "../images/gallery/photo12.jpg",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo13.jpg",
    original: "../images/gallery/photo13.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo14.jpg",
    original: "../images/gallery/photo14.jpg",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "../images/gallery/photo15.jpg",
    original: "../images/gallery/photo15.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo16.jpg",
    original: "../images/gallery/photo16.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo17.jpg",
    original: "../images/gallery/photo17.jpg",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo18.jpg",
    original: "../images/gallery/photo18.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo19.jpg",
    original: "../images/gallery/photo19.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo20.jpg",
    original: "../images/gallery/photo20.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo21.jpg",
    original: "../images/gallery/photo21.jpg",
    width: 320,
    height: 179,
    caption: "",
  },
  {
    src: "../images/gallery/photo22.jpg",
    original: "../images/gallery/photo22.jpg",
    width: 320,
    height: 215,
    caption: "",
  },
  {
    src: "../images/gallery/photo23.jpg",
    original: "../images/gallery/photo23.jpg",
    width: 257,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo24.jpg",
    original: "../images/gallery/photo24.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo25.jpg",
    original: "../images/gallery/photo25.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "../images/gallery/photo26.jpg",
    original: "../images/gallery/photo26.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "../images/gallery/photo27.jpg",
    original: "../images/gallery/photo27.jpg",
    width: 320,
    height: 212,
    caption: "",
  },
  {
    src: "../images/gallery/photo28.jpg",
    original: "../images/gallery/photo28.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo29.jpg",
    original: "../images/gallery/photo29.jpg",
    width: 320,
    height: 183,
    caption: "",
  },
  {
    src: "../images/gallery/photo30.jpg",
    original: "../images/gallery/photo30.jpg",
    width: 240,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo31.jpg",
    original: "../images/gallery/photo31.jpg",
    width: 320,
    height: 190,
    caption: "",
  },
  {
    src: "../images/gallery/photo32.jpg",
    original: "../images/gallery/photo32.jpg",
    width: 320,
    height: 148,
    caption: "",
  },
  {
    src: "../images/gallery/photo33.jpg",
    original: "../images/gallery/photo33.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo34.jpg",
    original: "../images/gallery/photo34.jpg",
    width: 248,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo35.jpg",
    original: "../images/gallery/photo35.jpg",
    width: 320,
    height: 113,
    caption: "",
  },
  {
    src: "../images/gallery/photo36.jpg",
    original: "../images/gallery/photo36.jpg",
    width: 313,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo37.jpg",
    original: "../images/gallery/photo37.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo38.jpg",
    original: "../images/gallery/photo38.jpg",
    width: 320,
    height: 194,
    caption: "",
  },
  {
    src: "../images/gallery/photo39.jpg",
    original: "../images/gallery/photo39.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo40.jpg",
    original: "../images/gallery/photo40.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo41.jpg",
    original: "../images/gallery/photo41.jpg",
    width: 271,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo42.jpg",
    original: "../images/gallery/photo42.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo43.jpg",
    original: "../images/gallery/photo43.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo44.jpg",
    original: "../images/gallery/photo44.jpg",
    width: 320,
    height: 213,
    caption: "",
  },
  {
    src: "../images/gallery/photo45.jpg",
    original: "../images/gallery/photo45.jpg",
    width: 320,
    height: 179,
    caption: "",
  },
  {
    src: "../images/gallery/photo46.jpg",
    original: "../images/gallery/photo46.jpg",
    width: 320,
    height: 215,
    caption: "",
  },
  {
    src: "../images/gallery/photo47.jpg",
    original: "../images/gallery/photo47.jpg",
    width: 257,
    height: 320,
    caption: "",
  },
  {
    src: "../images/gallery/photo48.jpg",
    original: "../images/gallery/photo48.jpg",
    width: 320,
    height: 213,
    tags: [{ value: "Untitled", title: "Untitled" }],
    caption: "Untitled",
  },
]
