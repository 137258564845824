import { featured } from "../../data/Data"
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom"
import "./Featured.scss"

const FeaturedCard = () => {
  const { t } = useTranslation('services')
  const navigate = useNavigate()
  const redirectToServicePage = (event: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    if(path != ""){
      if(path.slice(0, 5) === "https"){
        window.open(path, '_blank')
      }
      else{
        navigate(path)
        window.scrollTo(0, 0)
      } 
    }
  }
  return (
    <>
      <div className='content grid3 mtop'>
        {featured.map((items, index) => (
          <div className='box' key={index} onClick={(event:any) => redirectToServicePage(event, items.path)}>
            <img src={items.cover} alt='' />
            <h4>{t(items.name)}</h4>
            <label>{t(items.total)}</label>
          </div>
        ))}
      </div>
    </>
  )
}

export default FeaturedCard
