import { configureStore } from '@reduxjs/toolkit'
import demandContactReducer from './components/contact/redux/demandContactSlice'
import roomReducer from './components/home/room/redux/roomSlice'
import calendarReducer from './components/calendar/calendarEventSlice'

export const store = configureStore({
  reducer: {
    demandContact: demandContactReducer,
    room: roomReducer,
    calendar: calendarReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch