import Heading from "../../common/Heading"
import "./Featured.scss"
import FeaturedCard from "./FeaturedCard"
import { useTranslation } from 'react-i18next'


const Featured = () => {
  const { t } = useTranslation('services')
  return (
    <>
      <section className='featured background'>
        <div className='container'>
          <Heading title={t('Our services')} subtitle={t('Find out all our services.')} />
          <FeaturedCard />
        </div>
      </section>
    </>
  )
}

export default Featured
