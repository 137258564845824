import React from 'react'
import { Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguageIcon from './LanguageIcon'
import './language-switch.scss'
import { BsChevronDown } from 'react-icons/bs'

interface Props {
  light?: boolean
  disablePreferencesFetch?: boolean
}

export function LanguageSwitch({ light, disablePreferencesFetch }: Props) {
  const { i18n } = useTranslation([])

  function onLanguageChanged(info: { key: string }) {
    i18n.changeLanguage(info.key)
    if (disablePreferencesFetch) return
  }

  const languagesList = [
    {
      label: 'English',
      key: 'en',
      icon: <LanguageIcon language="en" />,
      country_code: 'GB',
    },
    {
      label: 'Français',
      key: 'fr',
      icon: <LanguageIcon language="fr" />,
      country_code: 'FR',
    },
    {
      label: 'Tiếng Việt',
      key: 'vn',
      icon: <LanguageIcon language="vn" />,
      country_code: 'VN',
    },
  ]

  const menu = (
    <Menu onClick={onLanguageChanged} defaultSelectedKeys={[i18n.language]} selectable>
      {languagesList.map((lang) => (
        <Menu.Item key={lang.key}>
          {lang.icon} <span>{lang.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} className="language-dropdown">
      <div className="d-flex mr-05rem language-icon">
        <LanguageIcon language={i18n.language} />
        <span
          className={`language-text d-flex d-flex-middle ${
            light ? 'language-text--light' : ''
          }`}
        >
          {
            languagesList.find((element) => {
              return element.key == i18n.language
            })?.label
          }
          <BsChevronDown />
        </span>
      </div>
    </Dropdown>
  )
}